<template>
  <div>
    <div
      v-if="isGuest"
      class="ml-1"
    >
      <b-button
        class="color-white"
        variant="link"
        @click="logout"
      >
        <feather-icon
          size="21"
          icon="LogOutIcon"
        />
        <span
          class="d-none d-sm-block d-md-none d-xl-block float-right logout-span"
        >Logout</span>
      </b-button>
    </div>

    <div
      v-if="profile && !isGuest"
      class="switch-user-dropdown-container"
    >
      <b-dropdown
        right
        menu-class="custom-dropdown-menu mt-25"
        variant="link"
        toggle-class="text-decoration-none p-0 text-capitalize"
        no-caret
        no-flip
      >
        <template #button-content>
          <b-img-lazy
            v-if="currentAssociation.entity.logo"
            :src="currentAssociation.entity.logo"
            :alt="currentAssociation.entity.entityName"
            class="profile-img"
            @error.native="imageLoadError"
          />
          <b-badge
            v-else
            variant="light"
            class="text-capitalize profile-badge"
          >
            {{ userCode(currentAssociation.entity.entityName) }}
          </b-badge>
          <span class="ml-50 navbar-light">{{ currentAssociation.entity.entityName }}</span>
        </template>
        <template v-for="association in profile.userAssociations">
          <b-dropdown-item
            v-if="association.id !== switchedUserId"
            :key="association.id"
            class="hr-line"
            @click="switchUser(association)"
          >
            <div>
              <b-img-lazy
                v-if="association.entity.logo"
                :src="association.entity.logo"
                :alt="association.entity.entityName"
                class="dropdown-item-img"
                @error.native="imageLoadError"
              />
              <b-badge
                v-else
                variant="light"
                class="text-capitalize profile-badge"
              >
                {{ userCode(association.entity.entityName) }}
              </b-badge>
              <span class="entity-name-text text-capitalize">
                {{ association.entity.entityName }}
              </span>
            </div>
          </b-dropdown-item>
        </template>
        <b-dropdown-item
          v-if="isEntityAdmin"
          @click="openAddStoreModal"
        >
          <div>
            <feather-icon
              class="dropdown-item-img"
              icon="PlusIcon"
            />
            <span class="entity-name-text"> {{ isRetailer ? 'Add new store' : 'Add new brand' }} </span>
          </div>
        </b-dropdown-item>
        <b-dropdown-item @click="logout">
          <div>
            <logout-icon class="dropdown-item-img" />
            <span class="entity-name-text"> Sign out </span>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <b-modal
      v-if="isEntityAdmin"
      id="add-store-modal"
      :title="dynamicTitle"
      class="p-1"
      hide-footer
      centered
      title-class="h4"
      @hide="closeAddStoreModal"
    >
      <validation-observer #default="{ invalid }">
        <b-form
          class="w-100"
          @submit.prevent="addStore"
        >
          <b-form-group
            :label="`${getTextForNewSotreAndBrand} name`"
            label-for="store_name_input"
            label-class="h5"
          >
            <validation-provider
              #default="{ errors }"
              :name="`${getTextForNewSotreAndBrand} name`"
              rules="required|min:2|max:80"
            >
              <b-form-input
                id="store_name_input"
                v-model="storeName"
                required
                :placeholder="`${getTextForNewSotreAndBrand} name`"
              />
              <div class="error-section">
                <small class="text-danger">{{ errors[0] }}</small>
              </div>
            </validation-provider>

          </b-form-group>
          <b-button
            class="float-right mt-1"
            type="submit"
            variant="primary"
            :disabled="isStoreNameAdding || invalid"
          >
            {{ isStoreNameAdding ? 'Adding...' : 'Add' }}
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BImgLazy,
  BButton,
  BBadge,
  BForm,
  BFormGroup,
  BFormInput,
  BModal,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import {
  ADD_STORE,
  FETCH_PROFILE,
  PURGE_AUTH,
  SWITCH_USER,
} from '@/store/modules/auth.module'
import analytics from '@/@core/utils/analytics'
import { formatObject, imageLoadError, parseErrorObject } from '@/@core/utils/utils'
import { apiToastError, apiToastSuccess } from '@/@core/utils/toast'
import constants, { exProfileData } from '@/constants'
import LogoutIcon from '@/@core/assets/svg-components/LogoutIcon.vue'
import UserRoleMixin from '@/views/apps/UserRoleMixin.vue'
import { saveSwitchedUserId } from '@/store/jwt.service'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min, max } from '@validations'

export default {
  name: 'SwitchProfileOrLogout',
  components: {
    BDropdown,
    BDropdownItem,
    BImgLazy,
    BButton,
    BBadge,
    FeatherIcon,
    LogoutIcon,
    // CameraIcon,
    BForm,
  BFormGroup,
  BFormInput,
  BModal,
  ValidationProvider,
  ValidationObserver,
  },
  mixins: [UserRoleMixin],
  data() {
    return {
      imageLoadError,
      switchedUserId: null,
      currentAssociation: null,
      storeName: '',
      isStoreNameAdding: false,
      min,
      max,
      required,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profileData,
    }),
    getTextForNewSotreAndBrand() {
      return this.isRetailer ? 'Store' : 'Brand'
    },
    dynamicTitle() {
      return `Add new ${this.getTextForNewSotreAndBrand}`
    },

  },
  watch: {
    profile: {
      handler() {
        this.setCurrentAssociation()
      },
      deep: true,
    },
  },
  created() {
      // Load associations only if not guest
      if (!this.isGuest) {
        this.setCurrentAssociation()
      }
  },
  methods: {
    setCurrentAssociation() {
        const userAssociation = this.$store.getters.getCurrentAssociation
        this.switchedUserId = userAssociation?.id
        this.currentAssociation = userAssociation
    },
    userCode(entityName) {
      if (!entityName) return '-'
      let initials = entityName?.split(' ').map(name => name[0])
      initials = initials.length > 2 ? [initials[0], initials[1]] : initials
      return initials.join(' ')
    },
    logout() {
      analytics.reset()
      this.$store.commit(PURGE_AUTH)
      this.$router.push({ name: 'auth-login' })
    },
    switchUser(userAssociation = {}) {
      const {
        entityId, entityType, role, id,
      } = userAssociation
      this.$store
        .dispatch(SWITCH_USER, {
            entityId, entityType, role, userAssociationId: id,
        })
        .then(async data => {
          await this.$store.dispatch(FETCH_PROFILE).then(data => {
            analytics.track(
              constants.TRACKS.ACTIONS.LOGIN,
              formatObject(data, exProfileData),
            )
          })
          saveSwitchedUserId(userAssociation.id)
          apiToastSuccess(data.message || 'User switched successfully', 'RefreshCwIcon')
          window.location.href = '/dashboard'
        })
        .catch(err => {
          apiToastError(err)
        })
    },
    openAddStoreModal() {
      analytics.track(constants.TRACKS.ACTIONS.ADD_STORE.RETAILER_CLICKS_ADD_STORE_BTN)
      this.$bvModal.show('add-store-modal')
    },
    addStore() {
      analytics.track(constants.TRACKS.ACTIONS.ADD_STORE.RETAILER_CLICKS_ADD_BTN_ON_ADD_STORE_POPUP, {
        storeName: this.storeName,
      })
      this.isStoreNameAdding = true
      this.$store.dispatch(ADD_STORE, { storeName: this.storeName }).then(res => {
        this.isStoreNameAdding = false
        this.$bvModal.hide('add-store-modal')
        this.$store.dispatch(FETCH_PROFILE)
        apiToastSuccess(res.data.message || 'Store added successfully')
        analytics.track(constants.TRACKS.ACTIONS.ADD_STORE.RETAILER_ADDS_STORE_SUCCESSFULLY, {
          storeName: this.storeName,
        })
      }).catch(err => {
        this.isStoreNameAdding = false
        apiToastError(err)
        analytics.track(constants.TRACKS.ACTIONS.ADD_STORE.RETAILER_STORE_ADD_FAILED, {
          storeName: this.storeName,
          error: parseErrorObject(err),
        })
      })
    },
    closeAddStoreModal() {
      this.storeName = ''
      analytics.track(constants.TRACKS.ACTIONS.ADD_STORE.RETAILER_CLICKS_CANCEL_BTN_ON_ADD_STORE_POPUP)
    },
  },
}
</script>

<style lang="scss" >
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";
$profile-img-resolution: 35px;

  .profile-badge {
    border-radius: 50%;
    background: $badge-color;
    width: $profile-img-resolution;
    height: $profile-img-resolution;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
.profile-img {
  width: $profile-img-resolution;
  height: $profile-img-resolution;
  border-radius: 50%;
}

.dropdown-item-img {
  height: $profile-img-resolution - 5;
  width: $profile-img-resolution - 5;
  border-radius: 50%;
}
.entity-name-text {
  margin-left: 7px;
}
.hr-line {
  border-bottom: 0.426768px solid $hr-color;
}
.switch-user-dropdown-container {
  padding-left: 13px;
}
.custom-dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}
.error-section {
  height: 10px;
}
</style>
