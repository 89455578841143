<template>
  <div class="navbar-container d-flex content align-items-center justify-content-between">

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center d-none d-flex" />

    <div class="nav navbar-nav navbar-center align-items-center d-flex">
      <p class="fontSelect color-white">
        {{ selectedSelectionsCount }} {{ selectedSelectionsCount > 1 ? 'selections selected': 'selection selected' }}
      </p>
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center">
      <template>
        <b-button
          class="mr-1"
          variant="custom-secondary"
          @click="onClickShare"
        >
          Share
        </b-button>
        <b-dropdown
          class="bi bi-three-dots-vertical select-action ml-0 mr-1"
          right
          variant="outline-custom-light"
          menu-class="mt-50"
          no-caret
        >
          <template #button-content>
            <span> Other actions </span>
            <feather-icon icon="MoreVerticalIcon" />
          </template>
          <b-dropdown-item @click="selectAllSelections">
            Select All
          </b-dropdown-item>
          <b-dropdown-item @click="deselectAllSelections">
            Deselect All
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <feather-icon
        icon="XIcon"
        size="25"
        class="cursor-pointer"
        @click="closeMultiSelectNav"
      />
    </b-navbar-nav>
  </div>
</template>

  <script>
  import {
    BNavbarNav, BDropdown, BDropdownItem, BButton,
  } from 'bootstrap-vue'
  import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
  import UserRoleMixin from '@/views/apps/UserRoleMixin.vue'
import { MULTI_SELECT_MODAL_ID, RESET_SELECTED_SELECTIONS } from '@/store/modules/multi-select.module'
import analytics from '@/@core/utils/analytics'
import constants from '@/constants'

const { SHARE_MULTIPLE_SELECTIONS } = constants.TRACKS.ACTIONS

  export default {
    name: 'AppHorizontalLayoutSelectionMultiSelect',
    components: {
      BDropdown,
      BDropdownItem,
      BButton,
      // Navbar Components
      BNavbarNav,
      FeatherIcon,
    },
    mixins: [UserRoleMixin],
    computed: {
      selectedSelections() {
        return this.$store.getters.getSelectedSelections || []
      },
      selectedSelectionsCount() {
        return this.selectedSelections.length
      },
    },
    methods: {
        onClickShare() {
          this.$bvModal.show(MULTI_SELECT_MODAL_ID)
          analytics.track(SHARE_MULTIPLE_SELECTIONS.BRAND_CLICKS_SHARE_SELECTION_TO_OPEN_SHARE_POPUP, { selectedSelectionIds: this.selectedSelections })
        },
        selectAllSelections() {
          this.$root.$refs.selection.selectAllSelections()
        },
        deselectAllSelections() {
          this.$store.commit(RESET_SELECTED_SELECTIONS)
          analytics.track(SHARE_MULTIPLE_SELECTIONS.BRAND_CLICKS_DESELECT_ALL_SELECTIONS)
        },
        closeMultiSelectNav() {
          this.$store.commit(RESET_SELECTED_SELECTIONS)
          analytics.track(SHARE_MULTIPLE_SELECTIONS.BRAND_CLOSES_THE_MULTI_SELECT_SELECTION_NAV_BAR)
        },
    },
  }
  </script>
  <style lang="scss" >
  @import "src/@core/scss/base/bootstrap-extended/_variables.scss";

  .fontSelect{
      color: $black;
      font-weight: $font-weight-bold;
      font-size: $h2-font-size;
      margin-bottom: 0;
  }
  .select-action{
    margin-top: 4px;
    margin: 0 25px;
  }
  @media (max-width: 576px) {
    .fontSelect {
      font-size: $h5-font-size;
      margin-right: 0;
    }
    .select-span{
      margin-right: 0;
    }
    .select-action{
      margin: 0;
    }
  }
  </style>
