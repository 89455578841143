<template>
  <div
    v-if="isBannerVisible"
    id="banner"
  >
    <p id="cookies">
      We use cookies to ensure that we give you the best experience on our website. To find out more see our
      <a
        href="https://kingpin.global/privacy-policy.html"
        target="_blank"
      >Privacy Policy</a>
      <b-button
        class="button round"
        variant="light"
        @click="acceptCookie"
      >
        Accept
      </b-button>
    </p>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import { setCookies, getCookie } from '@/common-utils'

export default {
  components: {
    BButton,
  },
  data() {
    return {
      isBannerVisible: false,
      setCookies,
      getCookie,
    }
  },
  mounted() {
    if (this.getCookie('EU_COOKIE_LAW_CONSENT') == null) {
      this.isBannerVisible = true
    }
  },
  methods: {
    acceptCookie() {
      this.setCookies('EU_COOKIE_LAW_CONSENT', 30)
      this.isBannerVisible = false
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/pages/cookie-banner.scss";
</style>
