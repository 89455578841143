<template>
  <b-row
    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
    class="content-header"
  >

    <!-- Content Left -->
    <b-col
      class="content-header-left"
      cols="12"
      md="9"
    >
      <b-row
        class="breadcrumbs-top"
        :class="{'mt-1':showHeader, 'mb-2': showHeader, 'mb-md-0':showHeader}"
      >
        <b-col cols="12">
          <div
            class="breadcrumb-wrapper"
            :class="showBreadcrumb"
          >
            <b-breadcrumb
              class="d-flex"
              :class="{'px-0': showHeader, 'mt-1':showHeader}"
            >
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in routerBreadcrumb"
                :key="item.text"
                :active="item.active"
                :to="parseBreadcrumbRoute(item)"
              >
                <span
                  v-if="isShowBreadcrumb(item)"
                  v-html="parseBreadcrumbText(item)"
                />
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

const { ROLES } = constants

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
  props: {
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      regex: /\$([^\s-]+)/g,
      routerBreadcrumb: [],
    }
  },
  computed: {
    entityType() {
      return this.$store.getters.entityType
    },
    showBreadcrumb() {
      if (this.showHeader) {
        return {
          'd-block': true,
          'd-sm-none': true,
        }
      }

      return {
        'd-none': true,
        'd-sm-block': true,
      }
    },
  },
  watch: {
    $route: {
      handler(newValue) {
        this.routerBreadcrumb = newValue.meta.breadcrumb
      },
      immediate: true,
    },
  },
  created() {
    this.routerBreadcrumb = this.$route.meta.breadcrumb
  },
  methods: {
    isShowBreadcrumb(item) {
      if (item.hasOwnProperty('acl')) {
        if (this.entityType === ROLES.GUEST) {
          return true
        }
        if (item.acl.toLowerCase() !== this.entityType) {
          return false
        }
      }
      return true
    },
    parseBreadcrumbText(item) {
      if (item.dynamic) {
        if (typeof item.parse === 'function') {
          return item.parse(item)
        }

        return item.text.replace(this.regex, (match, contents) => this.$route.params[contents] ?? item.text)
      }
      return item.text
    },
    parseBreadcrumbRoute(item) {
      if (item.to && item.to.params) {
        for (const pK in item.to.params) {
          if (item.to.params.hasOwnProperty(pK)) {
            item.to.params[pK] = item.to.params[pK].replace(this.regex, (match, contents) => this.$route.params[contents] ?? item.to.params[pK])
          }
        }
      }
      return item.to
    },
  },
}
</script>
