<template>
  <layout-vertical>

    <router-view />

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>

<style lang="scss">
@import "src/@core/scss/base/bootstrap-extended/_variables.scss";
.ecommerce-application {
  .ecommerce-card {
    &:not(.no-hover):hover{
      transform: translateY(-5px);
      box-shadow: 0 4px 25px 0 rgba($black, 0.25);
    }
  }
}
</style>
