<template>
  <b-modal
    id="send-mail-notification"
    modal-class="custom-modal-variant email-notification-model"
    centered
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    :size="showForm ? 'lg' : 'md'"
    hide-footer
    header-class="py-1"
    @show="showModal"
  >
    <template #modal-title>
      <div class="elements-center color-dark-blue">
        <speaker-icon />
        <span class="ml-1"> Broadcast a message </span>
        <feather-icon
          icon="XIcon"
          size="20"
          class="close-icon"
          @click="closeNotificationModal"
        />
      </div>
    </template>
    <div class="notification-block scroll-bar">
      <template v-if="showForm">
        <div v-show="!showExpandedView">
          <div class="header">
            You will be able to broadcast your announcement to your selected
            groups of Retailers and email addresses.
          </div>
          <b-form-group>
            <template #label>
              Send To <span class="text-danger">*</span>
            </template>
            <!-- Disabled Default show and hide. show and hided using values -->
            <b-dropdown
              v-click-outside="hideDropdown"
              class="email-notification-dropdown w-100"
              variant="outline-none"
              toggle-class="w-100"
              :class="{ show: showDropdown }"
              :menu-class="`w-100 ${showDropdown ? 'show' : ''}`"
              :text="
                brandGroup.length
                  ? `${brandGroup.length} group${
                    brandGroup.length > 1 ? 's' : ''
                  } of Retailers selected`
                  : 'Select your preferred list of Retailers to message'
              "
              @hide.prevent
              @show.prevent
              @toggle="showDropdown = !showDropdown"
            >
              <template v-if="recipientList.length">
                <b-dropdown-item
                  :active="selectAll"
                  @click="selectAllGroups"
                >
                  <b-form-checkbox
                    v-model="selectAll"
                    class="notification-checkbox"
                    stacked
                    @change="selectAllGroups"
                  />
                  All of my Retailer({{ recipientList.length }})
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="[key, emailGroup] of Object.entries(EMAIL_GROUPS)"
                  v-show="brandEmailGroups[key].length > 0"
                  :key="emailGroup"
                  :active="brandGroup.includes(key)"
                  @click="addOrRemoveEmailGroup(key)"
                >
                  <b-form-checkbox
                    v-model="brandGroup"
                    class="notification-checkbox"
                    :value="key"
                    stacked
                    @change="addOrRemoveEmailGroup(key)"
                  />
                  {{ emailGroup }} ({{ brandEmailGroups[key].length }})
                </b-dropdown-item>
              </template>
              <b-dropdown-item
                v-else
                disabled
              >
                No groups available
              </b-dropdown-item>
            </b-dropdown>
            <div class="mt-50">
              <b-badge
                v-for="group of brandGroup"
                :key="group"
                class="notification-badge"
              >
                {{ EMAIL_GROUPS[group] }} ({{
                  brandEmailGroups[group].length
                }})
                <feather-icon
                  icon="XIcon"
                  size="16"
                  class="cursor-pointer"
                  @click="addOrRemoveEmailGroup(group)"
                />
              </b-badge>
            </div>
          </b-form-group>
          <b-form-group>
            <template #label>
              Additional Emails <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model="additionalMails"
              placeholder="Type in additional emails, seperated by comma"
              @blur="addAdditionalMailToArray()"
            />
            <span
              v-if="!isAllMailsValid"
              class="text-danger font-small-4"
            >
              Some of the emails are not valid please remove it
            </span>
            <div class="mt-50">
              <b-badge
                v-for="mail of additionalMailArray"
                :key="mail"
                class="notification-badge"
                :class="isValidMail(mail) ? '' : 'invalid'"
              >
                {{ mail }}
                <feather-icon
                  icon="XIcon"
                  size="16"
                  class="cursor-pointer"
                  @click="removeAdditionalMail(mail)"
                />
              </b-badge>
            </div>
          </b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Subject"
            vid="name"
            rules="min:5"
          >
            <b-form-group>
              <template #label>
                Subject <span class="text-danger">*</span>
              </template>
              <b-form-input
                v-model="subject"
                placeholder="Type in your message subject"
              />
              <span
                v-if="errors.length"
                class="text-danger font-small-4"
              >{{ errors[0] }}
              </span>
            </b-form-group>
          </validation-provider>
        </div>
        <div
          v-show="showExpandedView"
          class="mb-1"
        >
          <b-button
            variant="outline-secondary-info"
            @click="showExpandedView = false"
          >
            <feather-icon icon="ChevronLeftIcon" />
            Back
          </b-button>
        </div>
        <b-form-group class="position-relative">
          <template #label>
            Message <span class="text-danger">*</span>
          </template>
          <vue-editor
            v-model="message"
            :editor-toolbar="VUE_EDITOR_DEFAULT_EDIT_OPTIONS"
            placeholder="Type in your message"
            :class="showExpandedView ? 'expanded' : ''"
          />
          <span
            v-if="message && hasValidMessage"
            class="text-danger font-small-4"
          >Message should be atleast 20 characters</span>
          <span
            v-show="!showExpandedView"
            class="expand-icon"
            @click="showExpandedView = true"
          >
            <expand-icon />
          </span>
        </b-form-group>
      </template>
      <div
        v-else
        class="elements-center flex-column text-center"
      >
        <post-box-icon />
        <div class="mb-50 color-dark-blue font-weight-bolder">
          Your message was broadcasted to the retailers successfully!
        </div>
        <div class="mb-50 color-actions-text-grey">
          You can find your sent messages in the
          <b-link :to="{ name: 'messages' }">
            <span
              class="color-blue"
              @click="$bvModal.hide('send-mail-notification')"
            >
              Messages page
            </span>
          </b-link>
        </div>
        <b-button
          variant="secondary-info"
          class="my-1"
          @click="resetForm"
        >
          Brodcast another Announcement
        </b-button>
      </div>
    </div>

    <div
      v-show="!showExpandedView && showForm"
      class="elements-center"
    >
      <b-button
        variant="outline-secondary-info"
        class="mr-1"
        @click="closeNotificationModal"
      >
        Cancel
      </b-button>
      <b-button
        :disabled="!validateInput || isLoading"
        variant="secondary-info"
        @click="sendBroadcastMessage"
      >
        {{ isLoading ? 'Sending...' : 'Send' }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormGroup,
  BFormInput,
  BButton,
  BDropdown,
  BDropdownItem,
  BBadge,
  BFormCheckbox,
  BLink,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import SpeakerIcon from '@/@core/assets/svg-components/SpeakerIcon.vue'
import { constants as c, emailTemplates } from '@kingpin-global/kingpin-utils-frontend'
import store from '@/store'
import {
  GET_BROADCAST_MAIL_GROUPS,
  SEND_BROADCAST_MAIL,
} from '@/store/modules/notification.module'
import { apiToastWarning } from '@/@core/utils/toast'
import { VueEditor } from 'vue2-editor'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import ExpandIcon from '@/@core/assets/svg-components/ExpandIcon.vue'
import { formatObject, isValidMail, removeHtmlTags } from '@/@core/utils/utils'
import PostBoxIcon from '@/@core/assets/svg-components/PostBoxIcon.vue'
import analytics from '@/@core/utils/analytics'
import constants, { VUE_EDITOR_DEFAULT_EDIT_OPTIONS } from '@/constants'

const { EMAIL_GROUPS } = c

export default {
  components: {
    BModal,
    SpeakerIcon,
    ValidationProvider,
    BFormGroup,
    BFormInput,
    BButton,
    BDropdown,
    BDropdownItem,
    VueEditor,
    BFormCheckbox,
    BBadge,
    FeatherIcon,
    ExpandIcon,
    PostBoxIcon,
    BLink,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      subject: '',
      message: '',
      showDropdown: false,
      EMAIL_GROUPS,
      brandEmailGroups: {},
      brandGroup: [],
      selectAll: false,
      additionalMails: '',
      additionalMailArray: [],
      showExpandedView: false,
      isValidMail,
      recipientList: [],
      isLoaded: false,
      showForm: true,
      isLoading: false,
      VUE_EDITOR_DEFAULT_EDIT_OPTIONS,
    }
  },
  computed: {
    isAllMailsValid() {
      let isValid = true
      this.additionalMailArray.forEach(mail => {
        if (!isValidMail(mail)) {
          isValid = false
        }
      })
      return isValid
    },
    validateInput() {
      const isValid = !(this.subject.length < 5)
        && !this.hasValidMessage
        && (this.brandGroup.length || this.additionalMailArray.length)
        && this.isAllMailsValid
      return isValid
    },
    hasValidMessage() {
      return removeHtmlTags(this.message).length < 20
    },
    profileData() {
      return store.getters.profile
    },
  },
  created() {
    // Initializing empty mail groups
    Object.keys(EMAIL_GROUPS).forEach(emailGroup => {
      this.brandEmailGroups[emailGroup] = []
    })
  },
  methods: {
    hideDropdown() {
      this.showDropdown = false
    },
    getEmailGroups() {
      this.isLoaded = false
      store
        .dispatch(GET_BROADCAST_MAIL_GROUPS)
        .then(res => {
          this.brandEmailGroups = {
            ...res.data.data,
            retailersWithProductsInCarts: res.data.data.retailersWithProductsInCarts || [],
          }
          this.recipientList = [
            ...new Set(Object.values(this.brandEmailGroups).flat(1)),
          ]
          this.isLoaded = true
        })
        .catch(err => {
          this.isLoaded = true
          apiToastWarning(err)
        })
    },
    sendBroadcastMessage() {
      this.isLoading = true
      const payload = {
        subject: this.subject,
        message: this.message,
        recipients: {
          additionalEmails: this.additionalMailArray.filter(mail => isValidMail(mail)),
        },
      }
      Object.keys(this.brandEmailGroups).forEach(key => {
        if (this.brandGroup.includes(key)) {
          payload.recipients[key] = this.brandEmailGroups[key]
        } else {
          payload.recipients[key] = []
        }
      })
      store
        .dispatch(SEND_BROADCAST_MAIL, payload)
        .then(() => {
          this.isLoading = false
          this.showForm = false
          const recipientList = [
            ...new Set(Object.values(payload.recipients).flat(1)),
          ].toString()
          analytics.track(
            constants.TRACKS.ACTIONS.BRAND_SEND_MESSAGE,
            formatObject({
              subject: this.subject,
              message: removeHtmlTags(this.message),
              recipientList,
            }),
          )
        })
        .catch(err => {
          this.isLoading = false
          apiToastWarning(err)
        })
    },
    addOrRemoveEmailGroup(emailGroup) {
      const index = this.brandGroup.indexOf(emailGroup)
      if (index !== -1) {
        this.brandGroup.splice(index, 1)
      } else {
        this.brandGroup.push(emailGroup)
      }
      this.selectAll = this.brandGroup.length === 4
    },
    selectAllGroups() {
      if (this.selectAll) {
        this.selectAll = false
        this.brandGroup = []
      } else {
        this.selectAll = true
        this.brandGroup = []
        Object.keys(EMAIL_GROUPS).forEach(group => {
          if (this.brandEmailGroups[group].length) {
            this.brandGroup.push(group)
          }
        })
      }
    },
    addAdditionalMailToArray() {
      const mails = this.additionalMails.split(',')
      mails.forEach(mail => {
        mail = mail.replace(' ', '')
        if (mail) {
          this.additionalMailArray.push(mail)
        }
      })
      this.additionalMailArray = [...new Set(this.additionalMailArray)]
      this.additionalMails = ''
    },
    removeAdditionalMail(mail) {
      const index = this.additionalMailArray.indexOf(mail)
      if (index !== -1) {
        this.additionalMailArray.splice(index, 1)
      }
    },
    resetForm() {
      this.subject = ''
      this.message = ''
      this.additionalMailArray = []
      this.brandGroup = []
      this.showForm = true
      this.selectAll = false
      this.isLoading = false
    },
    closeNotificationModal() {
      const recipients = {
        additionalMails: this.additionalMailArray,
      }
      Object.keys(this.brandEmailGroups).forEach(key => {
        if (this.brandGroup.includes(key)) {
          recipients[key] = this.brandEmailGroups[key]
        } else {
          recipients[key] = []
        }
      })
      const recipientList = [
        ...new Set(Object.values(recipients).flat(1)),
      ].toString()
      // Send Tracking only if user cancel before submitting
      if (this.showForm) {
        analytics.track(
          constants.TRACKS.ACTIONS.BRAND_CANCELS_SENDING_MESSAGE,
          formatObject({
            subject: this.subject,
            message: removeHtmlTags(this.message),
            recipientList,
          }),
        )
      }
      this.$bvModal.hide('send-mail-notification')
    },
    showModal() {
      this.resetForm()
      const emailTemplateData = emailTemplates.BROADCAST_BY_BRAND(
            this.profileData.entityName,
            this.profileData.firstName,
          )
      this.subject = emailTemplateData.subject
      this.message = emailTemplateData.body.replaceAll('              ', '')
      this.getEmailGroups()
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-notification.scss';
</style>
