<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.68435 1.77783C5.27852 1.77783 1.69434 5.3665 1.69434 9.77783C1.69434 14.1892 5.27852 17.7778 9.68435 17.7778C12.381 17.7778 14.7713 16.4365 16.2182 14.3818L15.1362 13.6112C14.522 14.4876 13.7058 15.2028 12.7567 15.696C11.8076 16.1893 10.7537 16.446 9.68435 16.4445C5.99896 16.4445 3.026 13.4678 3.026 9.77783C3.026 6.08783 5.99896 3.11117 9.68435 3.11117C10.7536 3.10992 11.8074 3.36679 12.7565 3.86001C13.7055 4.35323 14.5218 5.06826 15.1362 5.9445L16.2182 5.17383C15.4813 4.1242 14.5029 3.26763 13.3657 2.67653C12.2284 2.08543 10.9657 1.77718 9.68435 1.77783V1.77783ZM14.5742 6.63183L13.6168 7.5905L15.1362 9.11117H7.02101V10.4445H15.1362L13.6168 11.9652L14.5742 12.9238L17.2376 10.2572L17.6943 9.77783L17.2369 9.2985L14.5742 6.63183Z"
      fill="#726F7E"
    />
  </svg>
</template>
<script>
export default {
  name: 'LogoutIcon',
}
</script>
