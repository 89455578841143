import { ref } from '@vue/composition-api'
import { isNavLinkActive, navLinkProps } from '@core/layouts/utils'

export default function useVerticalNavMenuLink(item) {
  const isActive = ref(false)

  const linkProps = navLinkProps(item)

  const updateIsActive = () => {
    // Force to false if ignoreActive passed
    if (typeof item.ignoreActive === 'boolean' && item.ignoreActive) {
      isActive.value = false
    } else {
      isActive.value = isNavLinkActive(item)
    }
  }

  return {
    isActive,
    linkProps,
    updateIsActive,
  }
}
